import ManPopUp from "../../asets/man-popUp.png";
import "./index.css";

const Modal = ({ setIsModalCloses }) => {
  return (
    <div className="modal">
      <div className="modal-info">
        <img src={ManPopUp} alt="" />
        <div className="modal-info-text">
          <div className="modal-container">
            <div className="modal-header"> Важная информация!</div>
            <div className="modal-overview1">
              Сидеть на унитазе дольше 3-5 мин не рекомендуют врачи. Пользуйся
              нашим таймером и ты не перетужишься до гемыча.
            </div>
            <div className="modal-overview2">
              Мы думаем о том, как вы какаете!
            </div>
            <div className="modalX" onClick={() => setIsModalCloses(true)}>
              X
            </div>
            <button
              className="modal-button"
              onClick={() => setIsModalCloses(true)}
            >
              Понял
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;

import React from 'react';
import Time from "../time/index";
import "./AssideInfo.css";
import Man from "../../asets/man.png";
import Logo from "../../asets/logo.png";
import Phone from "../../asets/iPhone13.png";

const AssideInfo = ({ isModalCloses }) => {
  const handleClick = () => {
    window.location.href = "https://skidosiki.ru?utm_source=gold";
  };

  return (
    <div className="info-container">
      <Time isModalCloses={isModalCloses} />
      <div className="containerAside" onClick={handleClick}>
        <img className="logo" src={Logo} alt="Logo" />
        <div className="text-name">ПОИСК ЛУЧШИХ ЦЕН</div>
        <img className="phone" src={Phone} alt="Phone" />
        <div className="text-aside">
          <div>КАКАХА КРУТИТСЯ - ВЫГОДНЫЕ ПОКУПКИ МУТЯТСЯ</div>
        </div>
        <img className="man" src={Man} alt="Man" />
      </div>
    </div>
  );
};

export default AssideInfo;


import CountFaces from "../countFaces/index";
import FullAss from "../../asets/full_ass.png";
import Govno from "../../asets/govno.png";
import Eyes from "../../asets/eyes.png";
import Hair from "../../asets/hair.png";
import "./Ass.css";

const Ass = ({ isModalCloses, width }) => {
  return (
    <div className="ass-count-container">
      {width > 1200 && <CountFaces isModalCloses={isModalCloses} /> }
      <div className="ass-container">
        <img className="ass-image" src={FullAss} alt="ass" />
        <img
          className={isModalCloses ? "govno spin" : "govno"}
          src={Govno}
          alt="govno"
        />
        <img className="eyes" src={Eyes} alt="eyes" />
        <img className="hair" src={Hair} alt="" />
      </div>
    </div>
  );
};

export default Ass;

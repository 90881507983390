import { useEffect, useState } from "react";

const Time = ({isModalCloses}) => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    let interval;
    if (isModalCloses) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isModalCloses, time]);

  return (
    <div>
    <div className="time-info">Время на сайте</div>
    <div className="time">
      <span>
        {Math.floor(time / 3600)
          .toString()
          .padStart(2, "0")}
        :
      </span>
      <span>
        {Math.floor((time % 3600) / 60)
          .toString()
          .padStart(2, "0")}
        :
      </span>
      <span>{(time % 60).toString().padStart(2, "0")}</span>
    </div>
  </div>
  )
}
export default Time;
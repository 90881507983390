import { useEffect, useState } from "react";
const CountFaces = ({ isModalCloses }) => {
  const [countFaces, setCountFaces] = useState(0);

  useEffect(() => {
    if (isModalCloses) {
      const taimerFacesRotate = 2000;
      const intervalId = setInterval(() => {
        setCountFaces((prevCount) => prevCount + 1);
      }, taimerFacesRotate);
      return () => clearInterval(intervalId);
    }
  }, [isModalCloses]);

  const controlPhrase = () => {
    const lastTwoDigits = countFaces % 100;
    if (
      lastTwoDigits === 11 ||
      lastTwoDigits === 12 ||
      lastTwoDigits === 13 ||
      lastTwoDigits === 14
    )
      return "ОБОРОТОВ";
    const lastDigit = countFaces % 10;
    if (lastDigit === 1) return "ОБОРОТ";
    if (lastDigit === 2 || lastDigit === 3 || lastDigit === 4) return "ОБОРОТА";
    return "ОБОРОТОВ";
  };
  return (
    <div className="ass-timer">
      {countFaces}
      <span>{controlPhrase()}</span>
    </div>
  );
};
export default CountFaces;

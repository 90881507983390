import { useEffect, useState, useRef } from "react";
import Modal from "./components/modal/index";
import AssideInfo from "./components/assideInfo/index";
import Ass from "./components/ass/index";
import Time from "./components/time/index";
import Footer from "./components/footer";
import "./App.css";
import CountFaces from "./components/countFaces";
import { Helmet } from "react-helmet";
import audioFile from './asets/muha.mp3'

function App() {
  const [isModalCloses, setIsModalCloses] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const audioRef = useRef(null);

  useEffect(() => {
    if (isModalCloses) {
      const playAudio = () => {
        if (audioRef.current) {
          audioRef.current.play().catch((error) => {
            console.error("Автоматическое воспроизведение не удалось:", error);
          });
        }
      };
      playAudio();
    }
  }, [isModalCloses]);

  useEffect(() => {
    document.title = "Пук-пук из попы звук!";

    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content =
      "Включай, когда садишься в туалет и засекай сколько профукал времени.";
    document.head.appendChild(metaDescription);

    const metaOgTitle = document.createElement("meta");
    metaOgTitle.setAttribute("property", "og:title");
    metaOgTitle.content = "Пук-пук из попы звук!";
    document.head.appendChild(metaOgTitle);

    const metaOgDescription = document.createElement("meta");
    metaOgDescription.setAttribute("property", "og:description");
    metaOgDescription.content =
      "Включай, когда садишься в туалет и засекай сколько профукал времени.";
    document.head.appendChild(metaOgDescription);

    return () => {
      document.head.removeChild(metaDescription);
      document.head.removeChild(metaOgTitle);
      document.head.removeChild(metaOgDescription);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>Пук-пук из попы звук!</title>
        <meta
          name="description"
          content="Включай, когда садишься в туалет и засекай сколько профукал времени."
        />
        <meta property="og:title" content="Пук-пук из попы звук!" />
        <meta
          property="og:description"
          content="Включай, когда садишься в туалет и засекай сколько профукал времени."
        />
      </Helmet>
      {windowSize.width < 1200 && (
        <div className="app_container_time">
          <CountFaces isModalCloses={isModalCloses} />
          <Time isModalCloses={isModalCloses} />
        </div>
      )}
      <Ass isModalCloses={isModalCloses} width={windowSize.width} />
      {windowSize.width > 1200 && <AssideInfo isModalCloses={isModalCloses} />}
      {!isModalCloses && <Modal setIsModalCloses={setIsModalCloses} />}
      {windowSize.width < 1200 && <Footer />}
      <audio ref={audioRef} loop>
        <source src={audioFile} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}

export default App;

import Man from "../../asets/man.png";
import Logo from "../../asets/logo.png";
import Click from '../../asets/click.png';
import "./Footer.css";

const Footer = () => {
  const handleClick = () => {
    window.location.href = "https://skidosiki.ru?utm_source=gold";
  };
  return (
    <div className="footer" onClick={handleClick}>
      <div className="logo-container">
        <img src={Logo} alt="" />
        <div>ПОИСК ЛУЧШИХ ЦЕН</div>
      </div>
      <div className="text-container">
        <img src={Man} alt="" />
        <div>КАКАХА КРУТИТСЯ - ВЫГОДНЫЕ ПОКУПКИ<br/> МУТЯТСЯ</div>
      </div>
      <img className="click" src={Click} alt=''/> 
    </div>
  );
};
export default Footer;
